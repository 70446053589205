import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class AcquiredScreen extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title="Webinars on YouTube ">
        <SEO title="OPTOTAX Webinars" />
        <div className="banner-body">
          <div className="banner-content mt-2">
            <h3 className="caption text-center">
              Optotax is now a part of Open Financial Technologies.
            </h3>
            <h5 className="caption">
              <p>
                <span className="bold">Optotax</span>, your trusted GST
                companion, is now a part of{" "}
                <a href="https://open.money" target="_blank">
                  <span className="bold">Open Financial Technologies</span>
                </a>
                . Optotax has built a loyal community of over{" "}
                <span className="bold">30,000 Tax Professionals</span>. This
                merger will provide the Optotax team the resources and
                capabilities to offer more services to our Tax Professional
                community.
              </p>

              <p>
                In the coming months, we plan to offer an{" "}
                <span className="bold">
                  end-to-end compliance and practice management suite
                </span>
                . Also, we will help our community to attract and retain clients
                by allowing Tax Professionals to offer value-added financial
                services and{" "}
                <span className="bold">
                  become trusted financial advisors to their clients
                </span>
                .
              </p>
              <p>
                In the meanwhile, nothing changes for our community.
                <span className="bold">
                  {" "}
                  Optotax will continue to be secure, trusted, fast, and easy as
                  always.
                </span>
              </p>
              <p>
                We stand by our commitment to helping Tax Professionals manage,
                digitize, and rapidly grow their practice while they provide
                more value to their clients.
              </p>
              <p>
                <span className="bold">
                  {" "}
                  We are excited and can not wait to show what we are building
                  next for you!
                </span>
              </p>
            </h5>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AcquiredScreen
